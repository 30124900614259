/**
 * Module dependencies.
 */

import { CSSProperties, useRef, useState } from 'react';
import { ControlProps } from './index';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { Svg } from 'src/components/core/svg';
import { materialThickLight, materialUltrathinLight } from './colors';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import styled from 'styled-components';
import volumeIcon from 'src/assets/svgs/20/volume.svg';
import volumeOffIcon from 'src/assets/svgs/20/volume-off.svg';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
`;

/**
 * `FloatingSliderWrapper` styled component.
 */

const FloatingSliderWrapper = styled.div`
  align-items: center;
  background-color: ${materialThickLight};
  border-radius: 32px;
  bottom: calc(100% + 36px);
  color: var(--color-neutral90);
  display: flex;
  gap: 8px;
  padding: 16px 24px;
  position: absolute;
  right: 0;
  transition: var(--transition-default);
  transition-property: opacity, visibility;

  [data-controls-visible='false'] &,
  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`;

/**
 * `RangeWrapper` styled component.
 */

const RangeWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ::before {
    background-color: var(--color-primaryForLight40);
    border-radius: 2px;
    content: '';
    height: 4px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: calc((1 - var(--value)) * 100% + var(--value) * 10px - 5px);
  }

  input[type='range'] {
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    width: 116px;

    :focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      background-color: ${materialUltrathinLight};
      border-radius: 2px;
      height: 4px;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background-color: var(--color-primaryForLight40);
      border-radius: 50%;
      height: 10px;
      margin-top: -3px;
      width: 10px;
    }

    &::-moz-range-track {
      background-color: ${materialUltrathinLight};
      border-radius: 2px;
      height: 4px;
    }

    &::-moz-range-thumb {
      background-color: var(--color-primaryForLight40);
      border: none;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
  }
`;

/**
 * Export `VolumeControl` component.
 */

export const VolumeControl = ({ videoRef, videoState }: Pick<ControlProps, 'videoRef' | 'videoState'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(ref, () => setIsOpen(false));

  return (
    <Wrapper ref={ref}>
      <FloatingSliderWrapper aria-hidden={!isOpen}>
        <Svg
          icon={videoState.muted ? volumeOffIcon : volumeIcon}
          onClick={() => {
            videoRef.current.muted = !videoRef.current.muted;
          }}
          size={'20px'}
          style={{ cursor: 'pointer' }}
        />

        <RangeWrapper style={{ '--value': videoState.volume } as CSSProperties}>
          <input
            max={1}
            min={0}
            onChange={({ target }) => {
              videoRef.current.volume = Number(target.value);
            }}
            step={0.01}
            type={'range'}
            value={videoState.volume}
          />
        </RangeWrapper>
      </FloatingSliderWrapper>

      <IconButton
        aria-label={'volume settings'}
        icon={volumeIcon}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        size={'small'}
        variant={'ghost'}
      />
    </Wrapper>
  );
};
