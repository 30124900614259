/**
 * Module dependencies.
 */

import { BackLink } from 'src/components/core/links/back-link';
import { Image } from 'src/components/core/image';
import { LESSON_PROGRESS_KEY, LessonProgress } from 'src/types/local-storage';
import { LessonPage } from 'src/api/entities/lessons/types';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { VideoFragment } from 'src/api/entities/media/types';
import { VideoPlayerCustom } from 'src/components/core/video-player-custom';
import { getFileType } from 'src/core/utils/files';
import { media } from 'src/styles/media';
import { useDeviceSource } from 'src/hooks/use-device-source';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useMemo } from 'react';
import { useTranslate } from 'src/context/i18n';
import clockIcon from 'src/assets/svgs/16/clock.svg';
import get from 'lodash/get';
import set from 'lodash/set';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = LessonPage & {
  index: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  color: var(--color-text);
  padding: calc(
      var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3
    )
    0 24px;
  position: relative;
  z-index: 1;

  ${media.min.ms`
    padding-bottom: 36px;
  `}

  ${media.min.md`
    padding-bottom: 48px;
  `}
`;

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;

  ${media.min.ms`
    margin-bottom: 40px;
  `}
`;

/**
 * `PropertiesWrapper` styled component.
 */

const PropertiesWrapper = styled.div`
  color: var(--color-neutral10);
  gap: 14px;
  margin-bottom: 20px;

  ${media.min.ms`
    margin-bottom: 48px;
  `}

  &,
  > div {
    align-items: center;
    display: flex;
  }

  > div {
    gap: 6px;
  }
`;

/**
 * `HeadingsWrapper` styled component.
 */

const HeadingsWrapper = styled.div`
  display: grid;
  gap: 16px;
  padding-right: 16px;

  ${media.min.ms`
    padding-right: 36px;
  `}

  ${media.min.md`
    padding-right: 48px;
  `}

  ${media.min.lg`
    padding-right: 64px;
  `}

  ${media.min.xl`
    padding-right: 96px;
  `}
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled.div`
  margin-bottom: 48px;
  margin-left: var(--gutter-cards);
  width: calc(100% - var(--gutter-cards));

  ${media.max.ms`
    margin-bottom: 24px;
    margin-right: var(--gutter-cards);
    width: calc(100% - 2 * var(--gutter-cards));
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius);
`;

/**
 * Export `LessonHero` component.
 */

export const LessonHero = (props: Props) => {
  const { asset, course, description, duration, id, index, thumbnail, title } = props;
  const { t } = useTranslate();
  const [_store, setValue] = useLocalStorage<LessonProgress>(LESSON_PROGRESS_KEY);
  const nextLessonkey = useMemo(() => {
    const nextLessonId = course.lessons[index + 1]?.id;

    return nextLessonId ? `${course.id}.${nextLessonId}` : undefined;
  }, [course, index]);

  const deviceAsset = useDeviceSource([asset?.desktop?.asset, asset?.mobile?.asset]);
  const thumbnailAsset = useDeviceSource([
    (asset as VideoFragment)?.desktop?.thumbnail,
    (asset as VideoFragment)?.mobile?.thumbnail
  ]);

  const fileType = getFileType(deviceAsset?.filetype);

  return (
    <Wrapper data-theme={'dark'}>
      <BackLinkWrapper>
        <BackLink href={course.url} iconSize={20} variant={'label2'}>
          {t('lesson.back')}
        </BackLink>
      </BackLinkWrapper>

      <AssetWrapper>
        {deviceAsset && fileType === 'video' && (
          <VideoPlayerCustom
            {...(thumbnailAsset && { poster: thumbnailAsset.url })}
            key={deviceAsset.url}
            onSaveProgress={progress =>
              setValue(oldValue => {
                if ((oldValue?.[course.id]?.[id]?.[0] ?? 0) >= progress) {
                  return oldValue;
                }

                const newValue = { ...oldValue };

                if (progress === 1 && nextLessonkey && !get(newValue, nextLessonkey)) {
                  set(newValue, nextLessonkey, [0, Date.now()]);
                }

                set(newValue, `${course.id}.${id}`, [progress, Date.now()]);

                return newValue;
              })
            }
            seoJsonData={{
              contentUrl: deviceAsset.url,
              description,
              name: title,
              thumbnailUrls: [thumbnailAsset?.url],
              uploadDate: deviceAsset.uploadedOn
            }}
          >
            <source src={deviceAsset.url} type={deviceAsset.filetype} />
          </VideoPlayerCustom>
        )}

        {deviceAsset && fileType === 'image' && <StyledImage alt={deviceAsset.alt ?? title} src={deviceAsset.url} />}

        {!deviceAsset && thumbnail && <StyledImage alt={thumbnail.alt ?? title} src={thumbnail.url} />}
      </AssetWrapper>

      <PropertiesWrapper>
        <Text fontWeight={400} variant={'paragraph2'}>
          {`${t('lesson.lesson')} ${index + 1}`}
        </Text>

        <div>
          <Svg icon={clockIcon} size={'16px'} />

          <Text fontWeight={400} variant={'paragraph2'}>
            {`${duration} ${t('labels.duration.minutes.short')}`}
          </Text>
        </div>
      </PropertiesWrapper>

      <HeadingsWrapper>
        <Text as={'h1'} fontWeight={400} variant={'heading2'}>
          {title}
        </Text>

        <Text as={'h2'} fontWeight={400} variant={'subtitle2'}>
          {description}
        </Text>
      </HeadingsWrapper>
    </Wrapper>
  );
};
