/**
 * Module dependencies.
 */

import { Page } from 'src/api/entities/pages/page/types';
import { isExternalUrl } from 'src/core/utils/url';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

/**
 * Export `useQueryParamsCapture` hook.
 */

export function useQueryParamsCapture(page: Page) {
  const router = useRouter();

  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      const link = (event.target as Element)?.closest('a');

      if (!link || !page.queryParametersCaptureEnabled) {
        return;
      }

      const url = new URL(link.href);
      const isWhitelisted = page?.queryCaptureDomainWhitelist?.some(domain => {
        const domainRegex = new RegExp(
          `^${domain.replaceAll('*', '[a-zA-Z]+').replaceAll('.', '\\.').replaceAll('/', '\\/')}$`
        );

        return domainRegex.test(url.hostname);
      });

      if (!isWhitelisted) {
        return;
      }

      const paramsWhitelist = page?.queryCaptureParametersWhitelist;
      const newQuery = { ...omit(router.query, 'slug'), ...url.searchParams };
      const updatedQuery = paramsWhitelist ? (pick(newQuery, paramsWhitelist) as Record<string, string>) : newQuery;

      url.search = new URLSearchParams(updatedQuery).toString();

      const newUrl = url.toString();

      event.preventDefault();

      if (isExternalUrl(newUrl) || link.target === '_blank') {
        window.open(newUrl, link.target);

        return;
      }

      router.push(newUrl);
    };

    document.addEventListener('click', handleLinkClick);

    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [page, router]);
}
