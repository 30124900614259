/**
 * Module dependencies.
 */

import { ControlProps } from './index';
import { IconButton } from 'src/components/core/buttons/icon-button';
import playIcon from 'src/assets/svgs/20/play.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  height: max-content;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  > span {
    transform: translateX(2px);
  }
`;

/**
 * Export `InitialPlayButton` component.
 */

export const InitialPlayButton = ({ videoRef, videoState }: Pick<ControlProps, 'videoRef' | 'videoState'>) =>
  videoState.currentTime === 0 &&
  !videoState.isPlaying && (
    <Wrapper data-theme={'dark'}>
      <StyledIconButton
        aria-label={'play video'}
        icon={playIcon}
        onClick={() => videoRef.current.play()}
        size={'large'}
        variant={'primary'}
      />
    </Wrapper>
  );
